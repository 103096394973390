import { defineStore } from 'pinia'
import type { PageContents } from '../../types/backendtypes'
import { useUserStore } from '../user/userStore';
import type { User } from '../user/userStore';
export interface editorState {
    user: User,
    editor: editorSettings
}

export interface editorSettings {
    preview: boolean,
    originalContent: PageContents,
    editorContent: PageContents
}

export const useEditorStore = defineStore('editor', {
    state: (): editorState => ({
        user: {} as User,
        editor: {
            preview: false,
            originalContent: {} as PageContents,
            editorContent: {} as PageContents
        }
    }),
    getters: {
        getOriginalContent: (state): PageContents => state.editor.originalContent,
        getEditorContent: (state): PageContents => state.editor.editorContent,
        getEditingUser: (state): User => {
            return useUserStore().user
        },
        contentChanged: (state): Boolean => {
            return (state.editor.originalContent == state.editor.editorContent);
        }
    },
    actions: {
        setPreview(preview: boolean): void {
            this.$state.editor.preview = preview;
        },
        setUser(user: User):void {
            this.user = user;
        },
        setOriginalContent(content: PageContents) {
            this.setUser(this.getEditingUser)
            this.editor.originalContent = content;
            this.setEditorContent()
        },
        setEditorContent(): void {
            this.editor.editorContent = Object.assign({}, this.editor.originalContent)
        }
    },
})